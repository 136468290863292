import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Banner.scss";
import CTA from "../components/CTA";
import Image from "../components/Image";

class Banner extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageSizes: { default: "100vw" }
        };
    }

    render() {
        const { text, image, cta } = this.props;
        const { imageSizes } = this.state;

        return (
            <div className="banner">
                <Image {...image} sizes={imageSizes} />
                <div className="banner__overlay">
                    <div className="banner__text p" dangerouslySetInnerHTML={{ __html: text }} />
                    {cta && cta.link && <CTA {...cta} />}
                </div>
            </div>
        );
    }
}

Banner.propTypes = {
    text: PropTypes.string,
    image: PropTypes.object,
    cta: PropTypes.object
};

export default Banner;
