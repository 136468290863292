import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MainMenu from "../components/MainMenu";
import MainMenuButtons from "./MainMenuButtons";
import "./Header.scss";
import { IconLogo } from "./Icon";
import Link from "./Link";
import Grid from "../components/Grid";

class Header extends PureComponent {
    static defaultProps = {
        modifiers: []
    };

    constructor(props) {
        super(props);
    }

    render() {
        let { color, opaque, currentPath } = this.props;
        const className = "header";
        let classNames = [className];

        // use black text on white
        if (opaque) {
            color = "black";
        }
        const modifiers = [color];

        modifiers.push(color);
        if (opaque) {
            modifiers.push("opaque");
        }

        classNames = classNames.concat(modifiers.map((modifier) => `${className}--${modifier}`));

        return (
            <header className={classNames.join(" ")} id="header">
                <Grid>
                    <div className={`header__logo header__logo--${color}`}>
                        <Link to="/">
                            <IconLogo />
                        </Link>
                    </div>
                    <MainMenu color={color} />
                    <MainMenuButtons color={opaque ? "black" : color} currentPath={currentPath} />
                </Grid>
            </header>
        );
    }
}

Header.propTypes = {
    color: PropTypes.string.isRequired,
    opaque: PropTypes.bool,
    currentPath: PropTypes.string
};

export default Header;
