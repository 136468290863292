import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Seo from "./components/seo";
// import * as Content from "./content";
import * as LayoutComponents from "./layout-components";
import { Helmet } from "react-helmet";

import baseFontUrl from "./fonts/gotham-book.woff";
import mediumFontUrl from "./fonts/gotham-medium.woff";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutScrolled: false
        };

        this.layoutRef = React.createRef();
        this.handleLayoutScroll = this.handleLayoutScroll.bind(this);
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleLayoutScroll, { rootMargin: "0px 0px -100% 0px" });
        this.observer.observe(this.layoutRef.current);
    }

    componentWillUnmount() {
        this.observer.unobserve(this.layoutRef.current);
    }

    handleLayoutScroll(entries) {
        entries.forEach((entry) => {
            const { isIntersecting } = entry;
            if (isIntersecting) {
                this.setState({ layoutScrolled: true });
            } else {
                this.setState({ layoutScrolled: false });
            }
        });
    }

    render() {
        const { layoutScrolled } = this.state;
        const StaticPage = this.props.data.wpPage;
        const { title, template, hero, layout, uri: currentPath } = StaticPage;
        const templateName = template.templateName ? template.templateName.replace(" ", "").toLowerCase() : "default";
        // const Tag = Content[TemplateName];
        const { components } = layout;
        let hasCurratedExperience = false;

        return (
            <div className="app">
                <Seo title={title} />
                <Helmet>
                    <link rel="preload" as="font" href={baseFontUrl} type="font/woff" crossOrigin="anonymous" />
                    <link rel="preload" as="font" href={mediumFontUrl} type="font/woff" crossOrigin="anonymous" />
                </Helmet>
                {hero && <LayoutComponents.Hero {...hero} />}
                <div className={`app__layout app__layout--${templateName}`} ref={this.layoutRef}>
                    {components &&
                        components.map((component, i) => {
                            // convert fieldname to layout
                            let { fieldGroupName } = component;
                            fieldGroupName = fieldGroupName.replace("Page_Layout_Components_", "");
                            const LayoutTag = LayoutComponents[fieldGroupName];
                            if (fieldGroupName === "CuratedExperience") {
                                hasCurratedExperience = true;
                            }

                            return <LayoutTag key={i} {...component} />;
                        })}
                </div>
                {/* <Tag {...StaticPage} /> */}
                <Header color={hero && hero.menuColor} opaque={layoutScrolled && !hasCurratedExperience} currentPath={currentPath} />
                <Footer />
            </div>
        );
    }
}

App.propTypes = {
    data: PropTypes.object
};

export default App;

export const pageQuery = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            uri
            template {
                templateName
            }
            hero {
                text
                textColor
                menuColor
                image {
                    mobile {
                        altText
                        srcSet
                        mimeType
                        mediaDetails {
                            height
                            width
                        }
                    }
                    desktop {
                        altText
                        srcSet
                        mimeType
                        mediaDetails {
                            height
                            width
                        }
                    }
                }
            }
            layout {
                components {
                    ... on WpPage_Layout_Components_CuratedExperience {
                        fieldGroupName
                        title
                        text
                        images {
                            image {
                                mobile {
                                    altText
                                    srcSet
                                    mimeType
                                    mediaDetails {
                                        height
                                        width
                                    }
                                }
                                desktop {
                                    altText
                                    srcSet
                                    mimeType
                                    mediaDetails {
                                        height
                                        width
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Layout_Components_Destination {
                        fieldGroupName
                        title
                        text
                        textColor
                        textPosition
                        image {
                            mobile {
                                altText
                                srcSet
                                mimeType
                                mediaDetails {
                                    height
                                    width
                                }
                            }
                            desktop {
                                altText
                                srcSet
                                mimeType
                                mediaDetails {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    ... on WpPage_Layout_Components_Headline {
                        fieldGroupName
                        text
                    }
                    ... on WpPage_Layout_Components_Text {
                        fieldGroupName
                        title
                        text
                        wideTitle
                    }
                    ... on WpPage_Layout_Components_LargeParagraph {
                        fieldGroupName
                        text
                    }
                    ... on WpPage_Layout_Components_Image {
                        fieldGroupName
                        image {
                            mobile {
                                altText
                                srcSet
                                mimeType
                                mediaDetails {
                                    height
                                    width
                                }
                            }
                            desktop {
                                altText
                                srcSet
                                mimeType
                                mediaDetails {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    ... on WpPage_Layout_Components_SubMenu {
                        fieldGroupName
                        items {
                            target
                        }
                        animateScroll
                    }
                    ... on WpPage_Layout_Components_Excerpt {
                        fieldGroupName
                        text
                    }
                    ... on WpPage_Layout_Components_BookingForm {
                        fieldGroupName
                        text
                    }
                    ... on WpPage_Layout_Components_NewsletterForm {
                        fieldGroupName
                        headline
                        text
                        consentText
                    }
                    ... on WpPage_Layout_Components_Slider {
                        fieldGroupName
                        title
                        ingress
                        cta {
                            text
                            link {
                                ... on WpPage {
                                    uri
                                }
                            }
                        }
                        images {
                            caption
                            image {
                                mobile {
                                    altText
                                    srcSet
                                    mimeType
                                    mediaDetails {
                                        height
                                        width
                                    }
                                }
                                desktop {
                                    altText
                                    srcSet
                                    mimeType
                                    mediaDetails {
                                        height
                                        width
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Layout_Components_Banner {
                        fieldGroupName
                        text
                        cta {
                            text
                            link {
                                ... on WpPage {
                                    uri
                                }
                            }
                        }
                        image {
                            mobile {
                                altText
                                srcSet
                                mimeType
                                mediaDetails {
                                    height
                                    width
                                }
                            }
                            desktop {
                                altText
                                srcSet
                                mimeType
                                mediaDetails {
                                    height
                                    width
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            id
            siteMetadata {
                title
                description
            }
        }
    }
`;
