import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./LargeParagraph.scss";
import Grid from "../components/Grid";

class LargeParagraph extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return (
            <Grid>
                <div className="large-paragraph p" dangerouslySetInnerHTML={{ __html: text }} />
            </Grid>
        );
    }
}

LargeParagraph.propTypes = {
    text: PropTypes.string.isRequired
};

export default LargeParagraph;
