import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Headline.scss";

class Headline extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return (
            <h2 className="headline" dangerouslySetInnerHTML={{__html: text}} />
        );
    }
}

Headline.propTypes = {
    text: PropTypes.string
};

export default Headline;
