import React, { PureComponent } from "react";
import "./BookingForm.scss";
import Button from "../components/Button";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Grid from "../components/Grid";

class BookingForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            submitModifiers: ["black"],
            numberOfMonths: 1,
            isFormLoading: false
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.setState({ numberOfMonths: window.innerWidth > 768 ? 2 : 1 });
    }

    handleInputChange(event) {
        const {
            target: { name, value }
        } = event;
        this.setState({ [name]: value });
    }

    handleFormSubmit(event) {
        event.preventDefault();
        this.setState({
            isFormLoading: true
        });

        let formData = new FormData();

        const fields = ["location", "tripStart", "tripEnd", "guests", "purpose", "email"];
        fields.forEach((field) => formData.append(field, this.state[field]));

        const request = new XMLHttpRequest();
        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    const response = JSON.parse(request.response);
                    const { OK, message } = response;
                    this.setState({
                        isFormLoading: false,
                        formSuccessMessage: OK ? message : null,
                        formErrorMessage: OK ? null : message
                    });
                } else {
                    this.setState({
                        isFormLoading: false,
                        formErrorMessage: "Something went wrong",
                        formSuccessMessage: null
                    });
                }
            }
        };

        request.open("POST", "https://content.icontravelstories.com/wp-content/themes/its/booking.php");
        request.send(formData);
    }

    render() {
        const { submitModifiers, numberOfMonths, isFormLoading, formSuccessMessage, formErrorMessage } = this.state;
        return (
            <Grid>
                <div className={`booking-form${isFormLoading ? " booking-form--loading" : ""}`}>
                    <form onSubmit={this.handleFormSubmit}>
                        <div className="booking-form__input">
                            <label className="booking-form__label" htmlFor="location">
                                Location
                            </label>
                            <div className="booking-form__field booking-form__field--first">
                                <input type="text" id="location" value={this.state.location} placeholder="Add location" name="location" onChange={this.handleInputChange} />
                                {/* honey pot */}
                                <input type="text" id="url" placeholder="Add URL" name="url" />
                            </div>
                        </div>
                        <div className="booking-form__input booking-form__input--double">
                            <label className="booking-form__label booking-form__label--50" htmlFor="trip-start">
                                Trip start
                            </label>
                            <label className="booking-form__label booking-form__label--50" htmlFor="trip-end">
                                Trip end
                            </label>
                            <DateRangePicker
                                startDate={this.state.tripStart} // momentPropTypes.momentObj or null,
                                startDateId="trip-start" // PropTypes.string.isRequired,
                                startDatePlaceholderText="Add date"
                                endDate={this.state.tripEnd} // momentPropTypes.momentObj or null,
                                endDateId="trip-end" // PropTypes.string.isRequired,
                                endDatePlaceholderText="Add date"
                                numberOfMonths={numberOfMonths}
                                noBorder={true}
                                hideKeyboardShortcutsPanel={true}
                                onDatesChange={({ startDate, endDate }) => this.setState({ tripStart: startDate, tripEnd: endDate })} // PropTypes.func.isRequired,
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                displayFormat="MMM DD YYYY"
                            />
                        </div>
                        <div className="booking-form__input">
                            <label className="booking-form__label" htmlFor="guests">
                                Guests
                            </label>
                            <div className="booking-form__field">
                                <input type="text" id="guests" name="guests" value={this.state.guests} placeholder="Add guests" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="booking-form__input">
                            <label className="booking-form__label" htmlFor="purpose">
                                Purpose
                            </label>

                            <div className="booking-form__field">
                                <input type="text" id="purpose" name="purpose" value={this.state.purpose} placeholder="Add purpose" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="booking-form__input">
                            <label className="booking-form__label" htmlFor="email">
                                Email
                            </label>
                            <div className="booking-form__field booking-form__field--last">
                                <input type="email" id="purpose" name="email" value={this.state.email} placeholder="Your email" required onChange={this.handleInputChange} />
                            </div>
                        </div>

                        <Button className="booking-form__submit" type="submit" modifiers={submitModifiers}>
                            Submit
                        </Button>
                        {formSuccessMessage && (
                            <div className="booking-form__success-message">
                                <p>{formSuccessMessage}</p>
                            </div>
                        )}
                        {formErrorMessage && <p className="booking-form__error">{formErrorMessage}</p>}
                    </form>
                </div>
            </Grid>
        );
    }
}

BookingForm.propTypes = {};

export default BookingForm;
