import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Button from "./Button";
import "./MainMenuButtons.scss";

const modifiers = ["black", "small"];

function MainMenuButtons(props) {
    const data = useStaticQuery(graphql`
        query MenuButtonsQuery {
            allWpMenuItem(filter: { locations: { eq: MAIN_MENU_BUTTONS }, parentDatabaseId: { eq: 0 } }) {
                nodes {
                    label
                    path
                }
            }
        }
    `);

    const { allWpMenuItem } = data;
    const { color, currentPath } = props;

    return (
        <ul className={`main-menu-buttons main-menu-buttons--${color}`}>
            {allWpMenuItem.nodes.map((item, i) => (
                <li className="main-menu-buttons__button" key={i}>
                    {item.path !== currentPath && (
                        <Button to={item.path} modifiers={modifiers}>
                            {item.label}
                        </Button>
                    )}
                </li>
            ))}
        </ul>
    );
}

MainMenuButtons.propTypes = {
    color: PropTypes.string.isRequired,
    currentPath: PropTypes.string
};

export default MainMenuButtons;
