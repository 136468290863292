import React, { Component } from "react";
import PropTypes from "prop-types";

class Source extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { srcSet, sizes, media, type } = this.props;

        let sizesString = [];
        if (sizes) {
            if (sizes.desktop) {
                sizesString.push(`(min-width: ${1024}px) ${sizes.desktop}`);
            }
            if (sizes.tablet) {
                sizesString.push(`(min-width: ${768}px) ${sizes.tablet}`);
            }
            if (sizes.mobile) {
                sizesString.push(sizes.mobile);
            }
            if (sizes.default) {
                sizesString.push(sizes.default);
            }
            sizes = sizesString.join(", ");
        }
        const webPSourceSet = srcSet
            .split(", ")
            .map((src) => src.replace(/ /g, ".webp "))
            .join(", ");
        return (
            <>
                <source srcSet={webPSourceSet} sizes={sizes} media={media} type={"image/webp"} />
                <source srcSet={srcSet} sizes={sizes} media={media} type={type} />
            </>
        );
    }
}

Source.propTypes = {
    srcSet: PropTypes.string.isRequired,
    sizes: PropTypes.object,
    media: PropTypes.string,
    type: PropTypes.string.isRequired
};

export default Source;
