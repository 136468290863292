import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Text.scss";
import parse from "html-react-parser";
import classNames from "classnames";

class Default extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { title, text, wideTitle, modifier } = this.props;

        return (
            <div className={classNames("text", { [`text--${modifier}`]: modifier }, { "text--wide-title": wideTitle })}>
                <h2>{title}</h2>
                {parse(text)}
            </div>
        );
    }
}

Default.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    wideTitle: PropTypes.bool,
    modifier: PropTypes.string
};

export default Default;
