import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Excerpt.scss";

class Excerpt extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return <div className="excerpt" dangerouslySetInnerHTML={{ __html: text }}></div>;
    }
}

Excerpt.propTypes = {
    text: PropTypes.string
};

export default Excerpt;
