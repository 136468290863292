import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./SubMenu.scss";
import slugify from "slugify";
import HashLink from "../components/HashLink";

class SubMenu extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { animateScroll, items } = this.props;

        return (
            <div className="sub-menu">
                <ul>
                    {items.map((item, i) => {
                        const url = "#" + slugify(item.target, { lower: true });
                        return (
                            <li key={i} className={"sub-menu__item"}>
                                <HashLink href={url} animateScroll={animateScroll}>
                                    {item.target}
                                </HashLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

SubMenu.propTypes = {
    items: PropTypes.array.isRequired,
    animateScroll: PropTypes.bool
};

export default SubMenu;
