import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./CTA.scss";
import Button from "./Button";

class CTA extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, link } = this.props;
        return <Button to={link.uri}>{text}</Button>;
    }
}

CTA.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired
};

export default CTA;
