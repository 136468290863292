import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CTA from "../components/CTA";
import "./Slider.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import Image from "../components/Image";
import Grid from "../components/Grid";

class Slider extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            swiperSettings: {
                // loop: true,
                slidesPerView: 1.73333,
                // slidesPerGroup: 2,
                spaceBetween: 8,
                threshold: 5,
                watchOverflow: true,
                breakpoints: {
                    // XXX-mk add tablet breakpoint
                    [768]: {
                        slidesPerView: 3,
                        spaceBetween: 32
                    },
                    [1024]: {
                        slidesPerView: 3,
                        spaceBetween: 85
                    }
                }
            },
            imageSizes: { mobile: "56vw", tablet: "27vw", desktop: "25vw" }
        };
    }

    render() {
        const { title, ingress, images, cta } = this.props;
        const { swiperSettings, imageSizes } = this.state;

        return (
            <Grid>
                <div className="slider">
                    <h2>{title}</h2>
                    <p>{ingress}</p>
                    <Swiper {...swiperSettings}>
                        {images.map((image, i) => (
                            <SwiperSlide key={i}>
                                <div className="slider__image">
                                    <Image {...image.image} sizes={imageSizes} />
                                    <div className="slider__image-caption">{image.caption}</div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="slider__cta">{cta && cta.link && <CTA {...cta} />}</div>
                </div>
            </Grid>
        );
    }
}

Slider.propTypes = {
    title: PropTypes.string,
    ingress: PropTypes.string,
    images: PropTypes.array,
    cta: PropTypes.object
};

export default Slider;
