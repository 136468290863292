import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "./Link";
import "./FooterMenus.scss";

export default function FooterMenus() {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            allWpMenu(filter: { locations: { in: [FOOTER_MENU_1, FOOTER_MENU_2, FOOTER_MENU_3, FOOTER_MENU_4] } }, sort: { fields: menuItems___nodes___locations, order: ASC }) {
                nodes {
                    name
                    locations
                    menuItems {
                        nodes {
                            label
                            path
                        }
                    }
                }
            }
        }
    `);

    const menus = data.allWpMenu.nodes;
    return (
        <div className="footer-menus">
            {menus.map((menu, i) => (
                <div className={`footer-menus__menu footer-menus__menu--${menu.locations[0]}`} key={`menu-${i}`}>
                    <div className="footer-menus__title">{menu.name}</div>
                    <ul>
                        {menu.menuItems.nodes.map((item, j) => (
                            <li className="footer-menus__item" key={`menu-${i}-item${j}`}>
                                <Link to={item.path}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}
