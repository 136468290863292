import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const Icon = (props) => <span className={`icon icon--${props.name}`}>{props.children}</span>;

const IconLogo = () => (
    <Icon name="logo">
        <svg viewBox="0 0 151 23" xmlns="http://www.w3.org/2000/svg">
            <mask id="a" fill="#fff">
                <path d="m0 23h150.887308v-22.62634426h-150.887308z" fill="#fff" fillRule="evenodd" />
            </mask>
            <g fill="currentColor" fillRule="evenodd">
                <path d="m0 22h2v-21h-2z" />
                <path d="m21.0854764 23c-6.2270764 0-10.820303-4.9691311-10.820303-11.2816885 0-6.25034429 4.5623571-11.34465576 10.9125394-11.34465576 3.9148424 0 6.2579458 1.40639344 8.4158325 3.46885246l-1.6647192 1.81285246c-1.8186946-1.7502623-3.8531034-3.00018033-6.7819828-3.00018033-4.7780714 0-8.3540935 3.90622951-8.3540935 9.00054097 0 5.0943115 3.6068916 9.0631312 8.3540935 9.0631312 2.9593769 0 4.9019212-1.1564099 6.9668276-3.1566558l1.6033523 1.594164c-2.2504951 2.3124426-4.7167045 3.8436393-8.6315468 3.8436393" />
                <path d="m45.8396576 2.65518033c-4.9324187 0-8.4467019 4.00011475-8.4467019 9.00054097 0 5.0004262 3.5760221 9.0631312 8.5084409 9.0631312 4.9324187 0 8.4467019-4.0001148 8.4467019-9.000541 0-5.00042625-3.5760221-9.06313117-8.5084409-9.06313117m0 20.34481967c-6.5658965 0-10.9746502-5.2191148-10.9746502-11.2816885 0-6.06295084 4.4701207-11.34465576 11.0363892-11.34465576 6.5658965 0 10.9742783 5.21911475 10.9742783 11.28206556 0 6.0629508-4.4701207 11.3442787-11.0360173 11.3442787" />
                <path d="m80.3662167.74893279h2.373973v21.87639341h-1.9421725l-13.9340271-17.93886882v17.93886882h-2.3736009v-21.87639341h2.2809926l13.5948349 17.53203281z" />
                <path d="m97.4785707 8.75757049h-.9100911v-7.23293442h-2.6741133v-.84195082h6.2583177v.84195082h-2.6741133z" />
                <path d="m106.560142 1.52474918h-2.457655v3.27580328h2.446497c1.194611 0 2.04817-.6225082 2.04817-1.67221312 0-1.00370491-.75128-1.60359016-2.037012-1.60359016zm2.128133 7.23293443-2.310002-3.13780328h-2.275786v3.13780328h-.898934v-8.07488525h3.425023c1.752492 0 2.87867.95770492 2.87867 2.41085246 0 1.34945902-.910092 2.13409836-2.161978 2.36485246l2.446125 3.29918033z" />
                <path d="m116.154184 1.68642787-1.820554 4.11813115h3.629578zm4.062123 7.07118033h-.966995l-.93315-2.13409836h-4.335114l-.94468 2.13409836h-.921248l3.629207-8.13219672h.842401z" />
                <path d="m128.012072.68264754h.967367l-3.42465 8.13257377h-.796655l-3.425022-8.13257377h1.001212l2.833295 6.95580328z" />
                <path d="m137.674771 1.5133623h-4.858779v2.7569836h4.346643v.83063935h-4.346643v2.8259836h4.915311v.83063935h-5.814244v-8.07488525h5.757712z" />
                <path d="m140.907286.68264754h.898934v7.23293443h4.48351v.84195082h-5.382444z" />
                <path d="m97.3195 18.1270918c1.7748079.3921312 2.5945222 1.0497049 2.5945222 2.2954754 0 1.3958361-1.1380789 2.2954754-2.7198596 2.2954754-1.2626725 0-2.2984729-.4268196-3.2543104-1.2917705l.5575099-.6688852c.8308719.7608852 1.6271551 1.1417049 2.7310172 1.1417049 1.0696453 0 1.7748079-.5651967 1.7748079-1.3841475 0-.7616394-.3979557-1.188082-2.0704852-1.5459017-1.8320838-.4038196-2.6737414-1.0037049-2.6737414-2.3418524 0-1.2917705 1.1031182-2.2147869 2.6168374-2.2147869 1.160766 0 1.9908941.3348197 2.7990788.9920164l-.5232931.7039508c-.7397512-.6115738-1.4795024-.8770164-2.2988448-.8770164-1.0350566 0-1.6948448.5772623-1.6948448 1.3155246 0 .7725738.4091133 1.1993934 2.1616059 1.5802131" />
                <path d="m105.957221 22.6027033h-.910091v-7.2329344h-2.674114v-.8419509h6.258318v.8419509h-2.674113z" />
                <path d="m114.833938 15.2314672c-1.820554 0-3.117815 1.4765246-3.117815 3.3225574 0 1.8452787 1.319948 3.3451803 3.140874 3.3451803 1.820182 0 3.117443-1.4765246 3.117443-3.3225574 0-1.8452786-1.319576-3.3451803-3.140502-3.3451803m0 7.5096885c-2.42381 0-4.050966-1.9263442-4.050966-4.1645082 0-2.2374098 1.650215-4.1871311 4.074025-4.1871311 2.423438 0 4.050594 1.9263443 4.050594 4.1645082 0 2.2374098-1.649843 4.1871311-4.073653 4.1871311" />
                <path d="m125.531767 15.3699197h-2.457655v3.2758033h2.446498c1.19461 0 2.04817-.6225082 2.04817-1.6722132 0-1.0037049-.751281-1.6035901-2.037013-1.6035901zm2.128133 7.2329344-2.310002-3.1378033h-2.275786v3.1378033h-.898933v-8.0748852h3.425022c1.752492 0 2.87867.9577049 2.87867 2.4108524 0 1.349459-.910091 2.1340984-2.161978 2.3648525l2.446125 3.2991803z" />
                <path d="m131.848663 22.6025902h.898933v-8.0748853h-.898933z" mask="url(#a)" />
                <path d="m142.203915 15.3584951h-4.858778v2.7569836h4.346643v.8306393h-4.346643v2.8259836h4.91531v.8306394h-5.814244v-8.0748853h5.757712z" mask="url(#a)" />
                <path
                    d="m148.293046 18.1270918c1.774808.3921312 2.594522 1.0497049 2.594522 2.2954754 0 1.3958361-1.138079 2.2954754-2.719859 2.2954754-1.262673 0-2.298473-.4268196-3.254311-1.2917705l.55751-.6688852c.830872.7608852 1.627155 1.1417049 2.731017 1.1417049 1.069646 0 1.774808-.5651967 1.774808-1.3841475 0-.7616394-.397955-1.188082-2.070485-1.5459017-1.832084-.4038196-2.673741-1.0037049-2.673741-2.3418524 0-1.2917705 1.103118-2.2147869 2.616837-2.2147869 1.160766 0 1.990894.3348197 2.799079.9920164l-.523293.7039508c-.739751-.6115738-1.479503-.8770164-2.298845-.8770164-1.035057 0-1.694845.5772623-1.694845 1.3155246 0 .7725738.409113 1.1993934 2.161606 1.5802131"
                    mask="url(#a)"
                />
            </g>
        </svg>
    </Icon>
);

const IconBurger = () => (
    <Icon name="burger">
        <svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
            <path d="m31 32v2h-22v-2zm0-7v2h-22v-2zm0-7v2h-22v-2z" fill="currentColor" fillRule="evenodd" transform="translate(-9 -18)" />
        </svg>
    </Icon>
);

const IconClose = () => (
    <Icon name="close">
        <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="square" strokeWidth="2">
                <path d="m13.172003 1.71985-11.428572 11.428572" />
                <path d="m13.220326 13.223695-11.428571-11.428572" />
            </g>
        </svg>
    </Icon>
);

Icon.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node.isRequired
};

export { Icon, IconLogo, IconBurger, IconClose };
