import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./NewsletterForm.scss";
import Button from "../components/Button";
import parse from "html-react-parser";

class NewsletterForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            submitModifiers: ["black"],
            isFormLoading: false
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.setState({ numberOfMonths: window.innerWidth > 768 ? 2 : 1 });
    }

    handleInputChange(event) {
        const {
            target: { name, value, type, checked }
        } = event;

        this.setState({ [name]: type === "checkbox" ? checked : value });
    }

    handleFormSubmit(event) {
        event.preventDefault();
        this.setState({
            isFormLoading: true
        });

        let formData = new FormData();

        const fields = ["email", "consent"];
        fields.forEach((field) => formData.append(field, this.state[field]));

        const request = new XMLHttpRequest();
        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    const response = JSON.parse(request.response);
                    const { OK, message } = response;
                    this.setState({
                        isFormLoading: false,
                        formSuccessMessage: OK ? message : null,
                        formErrorMessage: OK ? null : message
                    });
                } else {
                    this.setState({
                        isFormLoading: false,
                        formErrorMessage: "Something went wrong",
                        formSuccessMessage: null
                    });
                }
            }
        };

        // request.open("POST", "http://its.localhost/wp-content/themes/its/subscribe.php");
        request.open("POST", "https://content.icontravelstories.com/wp-content/themes/its/subscribe.php");
        request.send(formData);
    }

    render() {
        const { headline, text, consentText } = this.props;
        const { submitModifiers, isFormLoading, formSuccessMessage, formErrorMessage } = this.state;
        return (
            <div className="newsletter-form">
                <div className={`newsletter-form__container${isFormLoading ? " newsletter-form__container--loading" : ""}`}>
                    <h2>{headline}</h2>
                    {parse(text)}
                    <form onSubmit={this.handleFormSubmit}>
                        <div className="newsletter-form__input">
                            <label className="newsletter-form__label" htmlFor="email">
                                Email
                            </label>
                            <div className="newsletter-form__field">
                                <input type="email" id="email" value={this.state.email} placeholder="Your email" name="email" onChange={this.handleInputChange} required />
                            </div>
                        </div>
                        <div className="newsletter-form__input">
                            <input type="checkbox" id="consent" value={this.state.consent} name="consent" onChange={this.handleInputChange} />
                            <label htmlFor="consent">
                                <small dangerouslySetInnerHTML={{ __html: consentText }} />
                            </label>
                        </div>

                        <Button className="newsletter-form__submit" type="submit" modifiers={submitModifiers}>
                            Submit
                        </Button>

                        {formSuccessMessage && (
                            <div className="newsletter-form__success-message">
                                <p>{formSuccessMessage}</p>
                            </div>
                        )}
                        {formErrorMessage && <p className="newsletter-form__error">{formErrorMessage}</p>}
                    </form>
                </div>
            </div>
        );
    }
}

NewsletterForm.propTypes = {
    headline: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    consentText: PropTypes.string.isRequired
};

export default NewsletterForm;
