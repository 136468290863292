import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";

class Link extends PureComponent {
    render() {
        const { to, children, ...attributes } = this.props;
        const isExternal = to.includes("http");

        return isExternal ? (
            <a href={to} target="_blank" rel="noreferrer" {...attributes}>
                {children}
            </a>
        ) : (
            <GatsbyLink to={to} {...attributes}>
                {children}
            </GatsbyLink>
        );
    }
}

Link.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default Link;
