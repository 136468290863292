import animateScrollTo from "animated-scroll-to";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

class HashLink extends PureComponent {
    constructor(props) {
        super(props);

        this.handleScollTo = this.handleScollTo.bind(this);
    }

    handleScollTo(e) {
        e.preventDefault();
        const { animateScroll } = this.props;
        const {
            target: { href }
        } = e;
        const dest = document.getElementById(href.split("#")[1]);

        let { clientHeight: offset } = document.getElementById("header");
        offset *= -1;

        if (window.innerWidth < 768) {
            const {
                dataset: { mobileOffset }
            } = dest;
            if (mobileOffset) {
                offset += parseInt(mobileOffset, 10);
            }
        } else {
            offset += 5;
        }

        if (animateScroll) {
            animateScrollTo(dest, { verticalOffset: offset });
        } else {
            dest.scrollIntoView();
        }
    }

    render() {
        const { href, children, animateScroll, ...attributes } = this.props;
        return (
            <a href={href} onClick={this.handleScollTo} {...attributes}>
                {children}
            </a>
        );
    }
}

HashLink.propTypes = {
    href: PropTypes.string.isRequired,
    animateScroll: PropTypes.bool,
    children: PropTypes.node
};

export default HashLink;
