import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Image from "../components/Image";
import "./Hero.scss";

class Hero extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageSizes: { default: "100vw" }
        };
    }

    render() {
        const { text, textColor, image } = this.props;
        const { imageSizes } = this.state;

        return (
            <div className={`hero`}>
                <Image {...image} sizes={imageSizes} />
                {text && <div className={`hero__text hero__text--${textColor}`} dangerouslySetInnerHTML={{ __html: text }} />}
            </div>
        );
    }
}

Hero.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    image: PropTypes.object
};

export default Hero;
