import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Destination.scss";
import Image from "../components/Image";
import slugify from "slugify";

class Destination extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageSizes: { default: "100vw" }
        };
    }

    render() {
        const { title, text, image, textColor, textPosition } = this.props;
        const { imageSizes } = this.state;

        return (
            <div className="destination" id={slugify(title, { lower: true })} data-mobile-offset="49">
                <div className="destination__image">
                    <div className="destination__mobile-title">
                        <h2 className={`destination__title destination__title--${textColor}`}>{title}</h2>
                    </div>
                    <Image {...image} sizes={imageSizes} />
                </div>
                <div className={`destination__info destination__info--${textPosition}`}>
                    <h2 className={`destination__title destination__title--${textColor}`}>{title}</h2>
                    <div className={`destination__text destination__text--${textColor}`} dangerouslySetInnerHTML={{ __html: text }}></div>
                </div>
            </div>
        );
    }
}

Destination.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object,
    textColor: PropTypes.string,
    textPosition: PropTypes.string
};

export default Destination;
