import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Source from "./Source";
import "./Image.scss";
import stringHash from "string-hash";

class Image extends PureComponent {
    constructor(props) {
        super(props);
    }

    // eslint-disable-next-line class-methods-use-this
    ratio(details) {
        return `${(details.height / details.width) * 100}%`;
    }

    render() {
        const { mobile, desktop, sizes } = this.props;
        const id = "aspect-ratio--" + stringHash(mobile.srcSet + (desktop ? desktop.srcSet : ""));

        return (
            <div className="image">
                <style>{`#${id} { padding-bottom: ${this.ratio(mobile.mediaDetails)} }`}</style>
                {desktop && <style>{`@media (min-width: 1024px) { #${id} { padding-bottom: ${this.ratio(desktop.mediaDetails)} } }`}</style>}
                <figure id={id}>
                    <picture>
                        {desktop && <Source srcSet={desktop.srcSet} sizes={sizes} type={desktop.mimeType} media="(min-width: 1024px)" />}
                        <Source srcSet={mobile.srcSet} sizes={sizes} type={mobile.mimeType} />
                        <img src={"//:0"} />
                    </picture>
                </figure>
            </div>
        );
    }
}

Image.propTypes = {
    mobile: PropTypes.object.isRequired,
    desktop: PropTypes.object,
    sizes: PropTypes.object
};

export default Image;
