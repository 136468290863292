import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Button.scss";
import { Link } from "gatsby";
import HashLink from "./HashLink";

class Button extends PureComponent {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        modifiers: []
    };

    render() {
        const { children, modifiers, ...attributes } = this.props;

        let Tag = "button";
        if (attributes.to) {
            Tag = Link;
        } else if (attributes.href) {
            if (attributes.href.charAt(0) === "#") {
                Tag = HashLink;
            } else {
                Tag = "a";
            }
        } else if (attributes.htmlFor) {
            Tag = "label";
        } else {
            Tag = "button";
        }

        const className = "btn";
        if (attributes.className) {
            attributes.className += ` ${className}`;
        } else {
            attributes.className = className;
        }

        modifiers.forEach((modifier) => {
            attributes.className += ` ${className}--${modifier}`;
        });

        return <Tag {...attributes}>{children}</Tag>;
    }
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    modifiers: PropTypes.array.isRequired
};

export default Button;
