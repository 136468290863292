import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./CuratedExperience.scss";
import Image from "../components/Image";
import Text from "./Text";
import Grid from "../components/Grid";
import slugify from "slugify";

class CuratedExperience extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            imageSizes: { mobile: "100vw", tablet: "30vw" }
        };

        this.imagesRef = React.createRef();
        this.mobileTitleRef = React.createRef();
        this.tick = this.tick.bind(this);

        // keep track on if dom should be updated
        this.lastState = {
            currentImage: -1,
            titleProgress: 0
        };
    }

    componentDidMount() {
        this.tick();
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.frameId);
    }

    tick() {
        const scrollTop = document.documentElement.scrollTop;

        const { height, top } = this.imagesRef.current.getBoundingClientRect();

        // if scrolltop havent changed or if we are not inside images
        if (this.lastState.lastScrollTop === scrollTop || top > 0) {
            this.frameId = requestAnimationFrame(this.tick);
            return;
        }
        this.lastState.lastScrollTop = scrollTop;

        const numberOfImages = 3;
        let currentImage = 0;
        let progress = Math.abs(top) + window.innerHeight / 2;
        const part = 1 / numberOfImages;
        for (let i = numberOfImages - 1; i > 0; i -= 1) {
            if (progress > height * part * i) {
                currentImage = i;
                break;
            }
        }

        const titleProgress = Math.abs(top) / (height - window.innerHeight);
        if (titleProgress < 1 && titleProgress > 0 && this.lastState.titleProgress !== titleProgress) {
            this.lastState.titleProgress = titleProgress;

            const titleOffset = window.innerHeight - 137;
            this.mobileTitleRef.current.style.transform = `translateY(${Math.abs(titleOffset * titleProgress - titleOffset)}px)`;
        }

        if (this.lastState.currentImage !== currentImage) {
            this.lastState.currentImage = currentImage;

            const images = this.imagesRef.current.children[0].children;
            for (let i = 0; i < images.length; i += 1) {
                const image = images[i];
                if (i === currentImage) {
                    image.style.opacity = 1;
                } else {
                    image.style.opacity = 0;
                }
            }
        }

        this.frameId = requestAnimationFrame(this.tick);
    }

    render() {
        const { text, images, title } = this.props;
        const { imageSizes } = this.state;

        return (
            <div className="curated-experience" id={slugify(title, { lower: true })}>
                <Grid>
                    <div className="curated-experience__mobile-title" ref={this.mobileTitleRef}>
                        <h2>{title}</h2>
                    </div>
                    <div className="curated-experience__images-wrapper" ref={this.imagesRef}>
                        <div className="curated-experience__images">
                            {images.map((image, i) => {
                                return <Image {...image.image} sizes={imageSizes} key={i} />;
                            })}
                        </div>
                        <div className="curated-experience__images-spacer"></div>
                    </div>
                </Grid>
                <Text title={title} text={text} modifier="wide" />
            </div>
        );
    }
}

CuratedExperience.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.array
};

export default CuratedExperience;
