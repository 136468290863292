import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Link from "./Link";
import "./MainMenu.scss";
import { IconBurger, IconClose } from "./Icon";
import MobileSubMenu from "./MobileSubMenu";

function MainMenu(props) {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU }, parentDatabaseId: { eq: 0 } }, sort: { fields: order, order: ASC }) {
                nodes {
                    label
                    path
                }
            }
        }
    `);

    const { allWpMenuItem } = data;
    const { color } = props;
    const [visible, setVisible] = useState(false);

    // dont show main menu if empty
    if (allWpMenuItem.nodes.length === 0) {
        return <></>;
    }

    return (
        <>
            <div className={`main-menu main-menu--${color} main-menu--${visible ? "visible" : "hidden"}`}>
                <ul className={`main-menu__items`}>
                    {allWpMenuItem.nodes.map((item, i) => (
                        <li className="main-menu__item" key={i}>
                            <Link to={item.path}>{item.label}</Link>
                        </li>
                    ))}
                </ul>
                <MobileSubMenu />
                <a href="#" className="main-menu__close" onClick={() => setVisible(false)}>
                    <IconClose />
                </a>
            </div>
            <a href="#" className={`main-menu__icon main-menu__icon--${color}`} onClick={() => setVisible(true)}>
                <IconBurger />
            </a>
            {visible && <div className="main-menu__overlay" onClick={() => setVisible(false)}></div>}
        </>
    );
}

MainMenu.propTypes = {
    color: PropTypes.string.isRequired
};

export default MainMenu;
