import React, { PureComponent } from "react";
import "./Footer.scss";
import FooterMenus from "./FooterMenus";

class Footer extends PureComponent {
    constructor(props) {
        super(props);
    }

    // eslint-disable-next-line class-methods-use-this
    render() {
        return (
            <footer className="footer">
                <FooterMenus />
                <div className="footer__copy">© ICON Travel Stories Ike</div>
            </footer>
        );
    }
}

export default Footer;
