import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "./Link";
import "./MobileSubMenu.scss";

export default function MobileSubMenu() {
    const data = useStaticQuery(graphql`
        query MobileSubMenuQuery {
            allWpMenuItem(filter: { locations: { eq: MOBILE_SUB_MENU }, parentDatabaseId: { eq: 0 } }, sort: { fields: order, order: ASC }) {
                nodes {
                    label
                    path
                }
            }
        }
    `);

    const { allWpMenuItem } = data;

    return (
        <ul className="mobile-sub-menu">
            {allWpMenuItem.nodes.map((item, i) => (
                <li className="main-menu__item" key={i}>
                    <Link to={item.path}>{item.label}</Link>
                </li>
            ))}
        </ul>
    );
}
